@import './variables';
@import './mixins';

/* stylelint-disable */
html, body {
  font-size: 18px;
}
/* stylelint-enable */

body {
  font-family:
    helvetica,
    $body-font-family;
  line-height: 1.25;
  background-color: $color-white;
}

.divider {
  border-color: $color-border-darker;
}

.rtl {
  direction: rtl;
}

// compensate the mobile navbar menu that is displayed at the bottom of
// the screen
.app {
  @media (max-width: $size-lg) {
    padding-bottom: calc(3 * #{$mp-l});
  }
}

* a {
  color: inherit;

  &:hover, &:active, &:visited, &:focus {
    color: inherit;
    text-decoration: none;
    border: none;
    box-shadow: none;
  }

  &:focus-visible {
    border: 1px solid $color-white;
    outline-offset: 2px;
    outline: 3px dashed $color-deep-blue-lighter;
  }
}

button, .legacy-button {
  padding: 5px $mp-xxl 5px $mp-xxl;
  color: inherit;
  font: inherit;
  font-weight: bold;
  font-size: $font-s;
  background: none;
  border: none;
  outline: 0;
  cursor: pointer;
  transition:
    color $animation-duration-default ease-out,
    background-color $animation-duration-default ease-out,
    border-color $animation-duration-default ease-out;

  &.white {
    color: $color-deep-blue;
    background-color: $color-white;
    border: 2px $color-deep-blue solid;

    &:hover {
      color: $color-white;
      background-color: $color-deep-blue;
    }
  }

  &.deep-blue {
    color: $color-deep-blue;
    color: $color-white;
    background-color: $color-deep-blue;
    border: 2px $color-deep-blue solid;

    &:hover {
      color: $color-white;
      background-color: $color-red;
      border-color: $color-red;
    }
  }

  &:focus-visible {
    border: 1px solid $color-white;
    outline-offset: 2px;
    outline: 3px dashed $color-deep-blue-lighter;
  }
}

.direct-link {
  color: $color-deep-blue;
  font-weight: bold;
  font-size: $font-xs;
  text-decoration: underline;
  cursor: pointer;
  transition:
    color $animation-duration-default ease-out,
    fill $animation-duration-default ease-out;
  fill: $color-deep-blue;

  &:hover {
    color: $color-red;
    fill: $color-red;
  }

  &:visited {
    color: $color-deep-blue;
    fill: $color-deep-blue;
  }
}

.input-shadow {
  margin-bottom: $mp-xl;
  font-size: $font-xs;
  font-style: italic;
  border: none;
  border-radius: 0;
  outline: none;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.12);

  &:focus, &:active {
    font-style: normal;
    border: 1px $color-grey-light solid;
    box-shadow: unset;
  }
}

.header-sub-menu {
  position: fixed;
  top: $header-height;
  z-index: $zindex-2;
  width: 100%;
  height: $page-menu-height;
  color: $color-deep-blue;
  background-color: $color-white;
}

.page-content {
  padding-top: calc(#{$main-header-height} + #{$mp-xl});

  &.with-page-menu {
    padding-top: calc(#{$main-header-height} + #{$page-menu-height} + #{$mp-xl});
  }

  @media (max-width: $size-xl) {
    padding-top: calc(#{$main-header-height} + #{$secondary-header-height} + #{$mp-l});

    &.with-page-menu-hide-xl,
    &.with-page-menu {
      padding-top: calc(#{$main-header-height} + #{$secondary-header-height} + #{$page-menu-height} + #{$mp-l});
    }
  }
}

button.link,
button.link:hover,
button.link:active,
button.link:visited,
button.link:focus {
  padding: 0;
  color: inherit;
  font: inherit;
  background: none;
  border: none;
  outline: 0;
  cursor: pointer;
}

span.bullet::before {
  position: absolute;
  top: 0.25rem;
  left: 0;
  display: block;
  width: 0.3rem;
  height: 0.3rem;
  background-color: $color-grey-light;
  border: 0.1rem solid $color-grey-light;
  border-radius: 50%;
  content: "";
}

.rtl span.bullet::before {
  right: 0;
  left: unset;
}

.container-reader {
  max-width: $size-reader;
  margin: auto;
}

.container-page {
  max-width: $size-page;
  margin: auto;

  @media (max-width: $size-page) {
    padding-right: $mp-xl;
    padding-left: $mp-xl;
  }
}

.column {
  display: flex;
  flex-direction: column;
}

img.profile-picture {
  width: 60px;
  height: 60px;
  border-radius: 30px;
}

.text-lighten {
  color: $color-grey-dark;
}

form :required {
  background-image: url("../assets/images/asterisk.svg");
  background-repeat: no-repeat;
  background-position: right 5px top 5px;
  background-size: 0.4em;
}

.rtl form :required {
  background-position: left 3px top 3px;
}

input.validation:not(:placeholder-shown):invalid {
  background-color: unset !important;
  border: 1px solid $color-red !important;
  outline: none !important;
  box-shadow: unset !important;
}

.form-input:not(:placeholder-shown):invalid {
  border-color: $color-red;
}

.switch-deep-blue input:checked + .form-icon {
  background: $color-deep-blue;
  border-color: $color-deep-blue;
}

.error {
  color: $color-red;
  font-size: $font-s;
}

.success {
  color: $color-green;
  font-size: $font-s;
}

.banner-section {
  margin-top: $mp-m;
  margin-bottom: $mp-m;
  padding-top: $mp-m;
  padding-bottom: $mp-m;
  background-color: $color-white;
}
